* {
  margin:0;
  padding:0;
}
html, body {
  height: 100%;
}
#root {
  height: 100%;
}

.container1 {
  height: 100%;
  color: rgb(24, 24, 24);
  /*background-color: whitesmoke;*/
  font-family: 'PT sans',sans-serif!important;
  font-size: 20px;
}
/*body {
  color: rgb(24, 24, 24);
  background-color: whitesmoke;
  font-family: 'PT sans',sans-serif!important;
  font-size: 20px;
}*/
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
p {
  font-size: 25px;
  padding:10px;
}
.smallerfont {
  font-size: 20px;
}
h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight:normal;
  font-size: 35px;
  margin: 10px;
}
h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight:normal;
  font-size: 30px;
  margin-bottom: 0.4em;
  margin-top:0.8em;
}
h3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight:normal;
  font-size: 25px;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
ul,ol {
  margin-top: 3px;
  margin-left:40px;
}
.dividerstyle {
  border-top: 1px solid #bbb;
  margin-top: 35px;
  margin-bottom: 20px;
}
.container2 {
  min-height: 100%;
  /*min-width: 100%;*/

  /* These properties force the footer to the bottom of the div */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /*background-image:url("nature-2609647_1920.jpg");*/
  background-color: whitesmoke;
  /*background-repeat: repeat;*/
  background-attachment: fixed;
  background-size:cover;
}
.main {
  /*overflow:auto;*/
  /*padding-bottom:30px;*/
  /*background-color:black;*/
}
.navigationbar{
  background-color: whitesmoke;
  border-bottom:black solid 1px;
}
#footer{
  text-align: center;
  position:relative;
  height:30px;
  margin-top:-30px;
  clear:both;
}
.tablebutton {
  height:5px;
  width: 5px;
}
.button.exercise, .markbookbutton, .button.innavigationbar, .dropdown-trigger, .deleteclassbutton {
  background:none;
  color:#3f51b5;
  /*max-width: 60%;*/
  margin-left:0.3em;
  border: solid 1px;
  border-color: #3f51b5;
  border-radius: 0.75em;
  /*border:none;*/
  font-weight: bold;
  padding:0.3em;
  padding-right:0.4em;
  padding-left: 0.4em;
  padding-top:0.2em;
  text-align: left;
  text-decoration: none;
}
.show-key-button{
    background:none;
    color:#3f51b5;
    /* margin-left:auto; */
    border: solid 1px;
    border-color: #3f51b5;
    border-radius: 0.75em;
    font-weight: bold;
    padding:0.3em;
    padding-right:0.4em;
    padding-left: 0.4em;
    padding-top:0.2em;
    text-align: center;
    justify-content: center;
    margin-right: 1em;
    margin-bottom: 1em;
}
.button.exercise .pen-icon {
  margin-left: -1.3em;
}
.button.exercise .worksheet-name {
  margin-left: 0.1em;
}
.button.exercise{
  padding-left: 1.6em;
  width:100%;
}
/*
.button.innavigationbar {
  border-color:  #3f51b5;
  color: #3f51b5;
  border: solid 1px;
  background-color: white;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 0px 2px;  
}
*/
.button.current{
  background-color: white;
  border-left:black solid 1px;
  border-right:black solid 1px;
  color:black;
  border-radius:0px;
}
.button.innavigationbar.float{
  float:right;
}
.button.innavigationbar:hover{
  color: black;
}
.button.innavigationbar.privacy{
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.button {
  background-color: rgb(109, 195, 245);
  border: none;
  color: white;
  padding: 15px 10px;/*recently made this smaller*/
  min-width:2.1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  font-family: 'PT Sans',sans-serif!important;
  border-radius: 25px;
  transition-duration: 0.4s;
  cursor: pointer;
  text-decoration: none;
}
.button.green {
  background-color: rgb(74, 240, 69);
}
.button.red {
  background-color: rgb(253, 67, 34);  
}
.button.grey{
  background-color: rgb(148, 153, 148);
}
.button.faded, button.disabled{
background-color: rgba(109, 195, 245, 0.486);
}
/*
I've had to disable this until I can find a way to let it coexist with
the MaterialUI CSS for the new pages, if thats a problem, it can very easily be re-enabled
- Matt

.button.active,button:focus {
  border-color:rgb(46, 147, 206); //rgb(155, 138, 252);
  border-style:solid;
  outline:none;
  border-width: 2px;
  box-sizing: border-box;
}
*/
.button.iframe, .button.disabled.iframe, button.faded.iframe,.button.active.iframe,.button.hint.iframe {
  background-color: #cc3274;
  font-family: "Raleway", sans-serif;
    font-size: 13px;
    line-height: 24px;
    font-weight: 700;
    padding: 12px 34px;
    color: #fff;
    text-transform: uppercase;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    /*position: relative;
    display: inline-block;*/
    -webkit-appearance: button;
    cursor: pointer;
    border: 1px solid #d65050;
    float:right;
}
.button.disabled.iframe:hover{
  background-color: #fff;
  color: #cc3274;
}
.button.hint.iframe{
  float:left;

  margin:0;
  margin-top: 10px;
}
.textareaholder.iframe{
  margin-bottom: 10px;
  background: #f8faf5;
  border: 1px solid #c3d1a3;
  padding: 5px;
  padding-bottom:15px;
  padding-top:15px;
  list-style: none;
  overflow: auto;
}
.button.questionnumber.faded {
  background-color:  rgb(200, 204, 200);
}
.button.questionnumber {
  background-color: rgb(148, 153, 148);
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 5px;
}
.button.disabled{
  cursor: not-allowed;
}
.button.symboltoggle {
  background-color:  rgb(182, 138, 252);
  border: none;
  color:white;
  padding: 5px 10px;/*16px 32px;*/
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;  
}
/*line in middle made by putting boarder on the left side of a box surrounding text on the right side of the page*/
.vl {
  border-left: 2px solid lightblue;
  padding-left: 20px;
}
/*box around central text*/
.feedback, .center, .alignpreviousandnext{
  width: 90%;
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
}
.alignpreviousandnext{
  width:100%;
}
.center {
  /*margin: auto;*/
  margin-top: 1em;
  margin-bottom: 1.5em;
  /*border: 0px solid darkblue;*/
  padding: 20px;
  overflow: auto;  
  background-color: white;
  border-radius: 7px;
  border-radius: 0.6em;
  padding-top:10px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.center.worksheet{
  max-width: 750px;
}
.center.iframe{
  margin-top: 0em;
  margin-bottom: 0em;
  background-color: transparent;
  
/*  background: #f8faf5;
    border: 1px solid #c3d1a3;*/
}
.center.iframe{
  font-family: "Source Sans Pro", sans-serif;
  color: #404040;
  /*font-size:16px;*/
}
.info_text.iframe{
  font-size: 16px;
  font: 14px/1.7 "Source Sans Pro", sans-serif;
  padding-left: 0em;
}
.classheaderbar{
  color:grey;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
}
.classcodeinheader{
  align-self: flex-end;
  margin-right: 10px;
}
.assignmentnameinheader{
  color:grey;
  margin-top: 0;
}
.feedback{
  /*border: 0px solid darkblue;*/
  border-radius: 25px;
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.presentationfeedback{
  /*border: 0px solid darkblue;*/
  border-radius: 15px;
  padding: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 17px;
  white-space: pre-wrap;
}
.pupil_answer{
  white-space: pre-wrap; 
  margin-top: 2px;
  border-radius: 5px;
  padding-left:4px;
  padding-bottom:1px;
  height: max-content;
  min-height: 1.1em;
  padding-right:4em;
}
.pupil_answer.mini{
  display: inline;
  border-radius: 1em;
  width: 5em;
}
.useranswerouter {
  margin-right:4em;
  word-wrap: break-word;
}
.useranswerinner {
  display:inline;
}
.manualAdjust {
  float:right;
  margin-right:-4em;
}
.feedback.Correct, .feedbacksummarytext.correct, .presentationfeedback.correct, .pupil_answer.correct,.pupil_answer.mini.correct, .button.questionnumber.green {
  background-color:  rgb(137, 243, 134);
}
.feedback.Incorrect, .feedbacksummarytext.incorrect, .presentationfeedback.incorrect, .pupil_answer.incorrect, .pupil_answer.mini.incorrect, .button.questionnumber.red {
  background-color: rgb(247, 131, 111);
  }
.feedback.Hint, .button.hint, .feedback.incomplete, .feedbacksummarytext.hint, .presentationfeedback.hint, .pupil_answer.incomplete, .pupil_answer.mini.incomplete, .button.questionnumber.yellow{
  background-color: rgb(245, 247, 111);
}
.feedback.faded{
  color: rgba(0, 0, 0, 0.4);
}
.feedback.Correct.faded, .presentationfeedback.correct.faded, .button.questionnumber.fadedgreen{
  background-color:  rgba(138, 243, 134, 0.4);
}
.feedback.Incorrect.faded, .presentationfeedback.incorrect.faded, .button.questionnumber.fadedred {
  background-color: rgb(247, 131, 111, 0.4);
  }
.feedback.Hint.faded, .presentationfeedback.hint.faded, .button.questionnumber.fadedyellow {
  background-color: rgb(245, 247, 111, 0.4);
}
.submitbuttonsholder {
  text-align: center;
  padding: 0px;
  margin-top:15px;
}
.alignpreviousandnext {
  margin: auto;
  /*width: 90%; put in above*/
  /*border: 0px solid darkblue;*/
  padding: 0px;
  overflow: auto;
  text-align: center;
  margin-top: -64px;
}
.questionbuttonholder{
  margin-top: 15px;
}
img {
  max-width:100%;
  height: auto;
}
.column {
  float: left;
  padding: 1px;
}
/*creating two columns*/
.column.side {
  width: 50%;
}
.column.middle {
  width: 50%;
}
.inputtextarea {
  width:90%;
}
.textareaholder{
  text-align: center;
}
.infotextarea {
width:95%
}
.button.hint{
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
  border-radius: 10px;
  margin-top: 0px;
  color:black;
}
/*
.tabs{
  background-color:  whitesmoke;
  
  color: rgb(59, 59, 59);
  padding: 1px 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 10px -1px; 
}
  */
.adminErrorMsg{
  color:red;
  font-size:12px;
}
/*.footer {*/

.zebra_table_head{
  background-color: rgba(137, 221, 241, 0.514);
}
.zebra_table_body{
  vertical-align: top;
  text-align: left;
}
.zebra_table_body:nth-child(even){
  background-color: rgba(137, 221, 241, 0.514);
  border-top: cornflowerblue;
}
.markbookbutton,.button.innavigationbar {
  cursor:pointer;
  font-size: 16px;
  padding-left: 0.4em;
  padding-top: 0.4em;
  font-weight: normal;
  background-color: white; /*whitesmoke;*/
  border-color: #3f51b5;
  /*
  border: solid;
  border-color: #3f51b5;
  border-radius: 0.5em;
  float:left;
  color: #3f51b5;
  padding: 7px 21px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.8px;
    text-transform: none;
  */
    /*margin-right: 0.3em;*/
}
.react-dropdown-tree-select{
  cursor:pointer;
  font-size: 20px;
  padding-left: 0.4em;
  padding-top: 0.4em;
  font-weight: normal;
  background-color: white; /*whitesmoke;*/
  border-color: #3f51b5;
}
.dropdown-trigger {
  font-size: 16px;
  font-weight:normal;
}
.freezecol{
  position: absolute;
  /*left: 0;*/
  width: 5em;
  margin-left: -5em;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: clip;
  text-align: left;
}
.assignment_table_div{
  margin-top: 20px;
  width:80%;
  overflow-x:scroll;
  margin-left: 5em;
  margin-right: 5em;
}
.option{
  border-radius: 15px;
  padding: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 17px;
  width:100%;
  outline:none;
}
.option.pressed{
  background-color:  rgb(182, 138, 252);
  color: white;
}
.option.notpressed{
  background-color: rgb(46, 147, 206);
  color: white;
}
.option.notpressed.ms{
  background-color: rgb(225, 226, 226);
  color: black;
  text-align: left;
}
.option.pressed.ms{
  background-color: rgb(133, 240, 142);
  color: black;
  text-align: left;
}
.optionsholder{
  display: inline-block;
  text-align: center;
}
.optionsholder.ms{
  display: inline-block;
  text-align: left;
}
.video-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.video-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
.MClabel{
  float: right;
  padding-right:3px;
  padding-left:3px;
  margin-left:0.5em;
  border-radius:5px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.377);
  color: rgba(255, 255, 255, 0.596);
  border-style: solid;
  font-size: small;
}
.admin_page{
  background-color: whitesmoke;
}
.little{
  font-size:20px;
}
.FeedbackTypeControl, .FeedbackPriorityControl{
  display:inline;
}
.feedbacktypecontrol,.feedbacktypecontroloption{
  width: 20px;
  border-radius: 10px;
}
.feedbacktypecontroloption.hint,.feedbacktypecontrol.hint{
  background-color: rgb(245, 247, 111);
  color:rgb(245, 247, 111);
}
.feedbacktypecontroloption.correct,.feedbacktypecontrol.correct{
  background-color: rgb(137, 243, 134);;
  color:rgb(137, 243, 134);;
}
.feedbacktypecontroloption.incorrect,.feedbacktypecontrol.incorrect{
  background-color: rgb(247, 131, 111);
  color:rgb(247, 131, 111);
}
.floatingtools {
  position: fixed;
  float:right;
  text-align: right;
  bottom:50px;
  right:1px;
  /*width:100%;*/
}
.FloatingFilter, .HelpPage{
  position: fixed;
  /*float:left;*/
  text-align: left;
  vertical-align:center;
  top:0px;
  right:0px;
  background-color: gainsboro;
  border-color: gray;
  border-width: 4px;
  border-style: solid;
  width:80%;
  padding-left:20px;
  padding-right:20px;
}
.HelpPage{
  scroll-behavior: auto;
  bottom: 50px;
  overflow-y: scroll;
  font-size: smaller;
}
.scoreselect{
  width: 35px;
}
.filtername{
  min-width: 35px;
  background-color: white;
  border-color: grey;
  border-width: 1px;
  border-style: solid;
  padding:1px;
  margin:4px;
  font-size: small;
  margin-left:20px;
}
.FilterComponent{
  vertical-align: top;
}
.FilterComponentLeft{
  height:100%;
  margin-top:15px;
  margin-bottom:15px;
  min-width: 100px;
  background-color: white;
  border-color: gray;
  border-width: medium;
  border-style: solid;
  border-right-width: 0px;
}
.FilterComponentRight{
  background-color: white;
  border-color: gray;
  border-width: medium;
  border-style: solid;
  border-left-width: 0px;
  margin-top:15px;
  margin-bottom:15px;
  width:100%;
}
.LongtextMPcreatorcomponent{
  width:90%;
}
.filternametextarea{
  width:90%;
}
.inputtextarea{
  font: inherit;
  background-color: white;
  /*font-family: cursive;*/
  border-radius: 0.5em;
  background-attachment: local;
  background-origin: content-box;
  background-image:
    /*  linear-gradient(to right, red 0.5em, transparent 0.5em),
      linear-gradient(to left, blue 0.5em, transparent 0.5em),
      linear-gradient(to top, yellow 0.5em, transparent 0.5em),
      linear-gradient(to bottom, green 0.5em, transparent 0.5em),*/
      repeating-linear-gradient(white, white 2.5ex, grey 2.5ex, grey 2.6ex);
  line-height: 2.6ex;
  background-repeat: no-repeat;
  /*background-clip: content-box;*/
  padding:0.5em;
  padding-top: 0.25em;
  font-size: 20px;
}
.inputtextarea.disabled{
  background-image:
    /*  linear-gradient(to right, red 0.5em, transparent 0.5em),
      linear-gradient(to left, blue 0.5em, transparent 0.5em),
      linear-gradient(to top, yellow 0.5em, transparent 0.5em),
      linear-gradient(to bottom, green 0.5em, transparent 0.5em),*/
      repeating-linear-gradient(rgba(131, 131, 131,0), rgba(133, 131, 131,0) 2.5ex, grey 2.5ex, grey 2.6ex);
  background-color: lightgrey;
}
.MakeReportContainer{
  width:50%;
  border:1px solid #404040;
  border-radius: 5px
}
.MakeReportHeader{
 margin-left:0px;
}
.button.innavigationbar.close{
  margin-right: 3px;
  margin-left:0px;
}
/*button innavigationbar float*/

.submittedreportcontainer{
}
.submittedreport{
  font-size: small;
  background-color: white;
  width:fit-content;
}
.reportinputarea{
}
.reportinput{
  width:35em;
  max-width: 85%;
  height:3em;
  margin-top: 0.5em;
}
.reportsubmitbutton{

}
@media print {/*https://stackoverflow.com/questions/468881/print-div-id-printarea-div-only*/
  body * {
    visibility: hidden;
  }
  .printable, .printable * {
    visibility: visible;
  }
  .printable {
    position: absolute;
    left: 0;
    top: 0;
  }
  .notprintable, .notprintable * {
    visibility: hidden;
  }
  
}
.notprintable.duplicateID {
  background-color: #d65050;
}
.DeleteDialog{
  border-radius: 0.5em;
  background-color: white;
  border-color: gray;
  border-width: medium;
  border-style: solid;
  margin:auto;
  margin-top:15px;
  margin-bottom:15px;
  text-align: center;
  padding: 1em;
  width:fit-content;
  max-width: 85%;
  /*max-width: 700px;*/
  z-index: 2;
  top:45%;
  left:50%;
  transform: translate(-50%,-50%);
  position:fixed;
}
.questionstopractice{
  border-radius: 0.5em;
  border-color: gray;
  border-width: medium;
  border-style: solid;
  margin:auto;
  margin-bottom: 2em;
}
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 17px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}


/* Note from Matt: This makes sure the existing CSS doesnt interfere with the helper-text for MUI's text fields */
.MuiFormHelperText-root {
  padding: 0;
}

.classToken {
  margin: 0.5em;
  padding:0.25em;
  background-color: #adb7e2;
  border-radius: 0.5em;
  font-size:medium;
  display: inline-block;
  color: aliceblue;
  font-weight: bold;
}
.SelectClassToAssign{
  margin: 0.5em;
  padding:0.25em;
  background-color: #adb7e2;
  border-radius: 0.5em;
  font-size:medium;
  display: inline-block;
  /*color: aliceblue;*/
  color: rgb(58, 58, 58);
  font-weight: bold;
  width: 7.5em;
}

.parentindropdownselect .checkbox-item { display: none; }
/*.node.disabled.parentindropdownselect*/
.node-label {
  color:black
}
.react-dropdown-tree-select li.node.disabled i { 
  position: absolute; 
  width: 100%; 
  text-align: left; 
  cursor: pointer;
  opacity: 0;
}
.tag-list .tag-item .tag {display:none}
.tag-list .tag-item {margin:0px}
.tag-list .tag-item .search {
  margin:4px;
  font-size:16px;
}

/*.toggle.collapsed:after{
  content: 📁
}/*🖿 
.toggle.expanded:after{
  content: 📂
}*/

/*.button{
  cursor:pointer;
  border: 1px solid rgba(63, 81, 181, 0.5);
  border-radius:4px;
}*/
/*.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow { 
  position: absolute; 
  width: 100%; 
  text-align: left; 
  cursor: pointer;
}*/
.pointer {
  cursor:pointer;
}

.show-key-button-image {
  margin-right: 0.5em;
  height: 4em;         
  vertical-align: middle;
  text-align:middle;
}
.key-header-container {
  display: flex;
  align-items: center;
  width: 25%;
}
.markbook-box-style {
  max-width: 1.65em;
  width: 1.65em;
  min-width: 1.65em;
  font-size: smaller;
  text-align: center;
  border-radius: 0.3em;
}


.popup {
  position: fixed; /* Stay in place */
  padding: 2em;
  z-index: 1; /* Sit on top */
  left: 50%;
  top: 50%;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  transform: translate(-50%, -50%);
  background-color: #fefefe; /* Fallback color */
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  overflow: auto;
  border-radius: 10px;

  /* Center the popup content */
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-popup-button {
  background:none;
  position: absolute;
  top: 0;
  right: 0;
  color:#3f51b5;
  font-size: 24px;
  cursor:pointer;
  margin-left:0.3em;
  border: solid 1px;
  border-color: #3f51b5;
  border-radius: 0.75em;
  padding:0.3em;
  padding-right:0.4em;
  padding-left: 0.4em;
  padding-top:0.2em;
  text-align: left;
}
.exit_button{
  float: right;
    color: black;
    text-decoration: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.questions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* WorksheetViewer styling */
.WorksheetViewer {
  margin-left: 1em; /* Add space between the button and the viewer */
  display: inline-block;
}

/* AssignComponent styling */
.AssignComponent {
  margin-left: 1em; /* Add space between the viewer and the assignments */
  display: inline-block;
}

.classTokendontuse {
  display: inline-block;
  margin-right: 5px;
  background-color: #c3d1a3;
  border-radius: 0.5em;
  padding: 0.5em;
}

.showMoreButton, .showLessButton {
  margin-left: 10px;
  cursor: pointer;
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  font-size: 0.9em;
}

.showMoreButton:hover, .showLessButton:hover {
  text-decoration: none;
}