.tab-container {
  display: flex;
  height: 100vh; /* Make the container take up the full height of the viewport */
  width: 100%;
  margin-left:-20px;
}

.tabs {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
}

.tab {
  padding: 15px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background-color 0.3s, color 0.3s;
  border-left: 3px solid transparent; /* To indicate active tab */
  color: rgb(59, 59, 59);
  background-color: whitesmoke;

}

.tab-name {
  margin-left: 10px; /* Space between icon and text */
  font-size: 20px;
}

.tab.active {
  background-color: white;
  color: black;;
  border-left: 3px solid rgb(44, 56, 126); /* Highlight the active tab */
  border-right: none;
}

.tab:hover {
  background-color: navy;
  color: white;
}

.tab-content {
  flex: 1;
  padding: 0px 20px;
  overflow-y: auto;
  /*background-color: #ffffff; /* White background for the content */
}
