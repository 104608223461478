.assignments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0; /* Add some padding for spacing */
    border-bottom: 2px solid #ccc; /* Optional: add a border to separate the header from the rows */
    margin-bottom: 10px; /* Space between header and first row */
}

.header-title {
    flex: 1;
    font-weight: bold;
    text-align: left;
}

.header-toggle {
    font-weight: bold;
    text-align: right;
    margin-right: 34px; /* Adjust to align with toggles below */
}