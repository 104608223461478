.assignment-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px; /* Add some space below each row */
}

.assignment-button-container {
    flex: 1;
    text-align: left;
    margin-right: 20px; /* Add space between the button and toggle */
}

.markbookbutton {
    width: 100%; /* Ensure the button fills the container */
    max-width: 25em; /* Adjust max-width to allow the button to be longer */
    cursor: pointer;
    text-align: left; /* Align text to the left */
}

.assignment-toggle-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #007bff;
}

input:checked + .slider:before {
    transform: translateX(14px);
}
